import React, { Fragment, useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import { connect } from 'react-redux'
import { addPickupDateTime, addReturnDateTime } from '../actions/searchActions'
import { useTranslation } from 'next-i18next'
import { getDropOffMinDate, getInitialDate } from '../utils/dateutils'

function DateTimePicker({
  addPickupDateTime,
  addReturnDateTime,
  pickupDateTime,
  returnPickupTime,
}) {
  const [startDate, setStartDate] = useState(null)
  const [startTime, setStartTime] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [endTime, setEndTime] = useState(null)

  const startdateref: any = useRef()
  const timepickerref: any = useRef()
  const enddateref: any = useRef()
  const endtimepickerref: any = useRef()
  const { t, ready } = useTranslation()

  useEffect(() => {
    // console.debug('DateTimePicker useEffect[]', { pickupDateTime, returnPickupTime })
    if (pickupDateTime) {
      setStartDate(pickupDateTime)
      setStartTime(pickupDateTime)
    }

    if (returnPickupTime) {
      setEndDate(returnPickupTime)
      setEndTime(returnPickupTime)
    }
  }, [])

  useEffect(() => {
    // console.debug('DateTimePicker useEffect[startDate]', { startDate })
    addPickupDateTime(startDate)
  }, [startDate])

  useEffect(() => {
    // console.debug('DateTimePicker useEffect[endDate]', { startDate })
    addReturnDateTime(endDate)
  }, [endDate])

  const handleChange = (date) => {
    let isAfter = moment(date).isAfter(endDate)
    setStartDate(date)
    if (endDate === null || isAfter) {
      let _endDate = new Date(date)
      _endDate = new Date(_endDate.setTime(_endDate.getTime() + 7 * 86400000))
      setEndDate(_endDate)
    }

    if (date !== null) timepickerref.current.input.click()
  }

  const handleTimeChange = (time) => {
    if (startDate === null) startdateref.current.input.click()
    else if (time !== null) {
      setStartTime(time)
      let starttime = moment(time).format().split('T')[1]
      let startDateTimeString = startDate
      startDateTimeString.setHours(starttime?.split(':')[0])
      startDateTimeString.setMinutes(starttime?.split(':')[1])
      if (endTime === null) {
        setEndTime(time)
        let endDateTimeString = endDate
        endDateTimeString.setHours(starttime?.split(':')[0])
        endDateTimeString.setMinutes(starttime?.split(':')[1])
      }
    }
  }

  const handleChangeReturn = (date) => {
    let isAfter = moment(date).isAfter(startDate)
    if (isAfter) {
      // console.debug('DateTimePicker handleChangeReturn', { date })
      setEndDate(date)
      if (date !== null) endtimepickerref.current.input.click()
    }
  }

  const handleEndTimeChange = (time) => {
    if (endDate === null) enddateref.current.input.click()
    else if (time !== null) {
      setEndTime(time)
      let endtime = moment(time).format().split('T')[1]
      let endDateTimeString = endDate
      endDateTimeString.setHours(endtime?.split(':')[0])
      endDateTimeString.setMinutes(endtime?.split(':')[1])
    }
  }
  return (
    <>
      {ready && (
        <>
          <div className="date-picker-container">
            <div style={{ marginBottom: '20px' }}>
              <label htmlFor="pickup-date">{t('Pick-up date')}</label>&nbsp;
              <label htmlFor="pickup-time">{t('& Time')}</label>
            </div>
            <div className="pickupdateTime">
              <DatePicker
                minDate={getInitialDate()} // Prevent past date selection
                id="pickup-date"
                ref={startdateref}
                selected={startDate}
                onSelect={handleChange}
                className="date-container"
                shouldCloseOnSelect={true}
                placeholderText={t('Select Pickup Date')}
                dateFormat="dd MMM,yyyy"
                value={startDate}
              />
              <DatePicker
                id="pickup-time"
                ref={timepickerref}
                selected={startTime}
                timeIntervals={30}
                onSelect={handleTimeChange}
                onChange={handleTimeChange}
                className="time-container"
                shouldCloseOnSelect={true}
                placeholderText={t('& Time')}
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                showDateSelect={false}
                preventOpenOnFocus={true}
                showTimeSelect={startDate !== '' ? true : false}
                showTimeSelectOnly={startDate !== '' ? true : false}
              />
            </div>
          </div>

          <div className="date-picker-container">
            <div>
              <label htmlFor="dropoff-date">{t('Drop-off date')}</label>&nbsp;
              <label htmlFor="dropoff-time">{t('& Time')}</label>
            </div>
            <div className="pickupdateTime">
              <DatePicker
                id="dropoff-date"
                minDate={getDropOffMinDate(startDate)} // Prevent past date selection
                ref={enddateref}
                selected={endDate}
                className="date-container"
                onSelect={handleChangeReturn}
                shouldCloseOnSelect={true}
                placeholderText={t('Select Dropoff Date')}
                dateFormat="dd MMM, yyyy"
                value={startDate}
              />
              <DatePicker
                id="dropoff-time"
                ref={endtimepickerref}
                selected={endTime}
                className="time-container"
                onSelect={handleEndTimeChange}
                onChange={handleEndTimeChange}
                shouldCloseOnSelect={true}
                placeholderText={t('& Time')}
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                showDateSelect={false}
                showTimeSelect={endDate ? true : false}
                showTimeSelectOnly={endDate ? true : false}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    addPickupDateTime: (payload) => dispatch(addPickupDateTime(payload)),
    addReturnDateTime: (payload) => dispatch(addReturnDateTime(payload)),
  }
}

export default connect(null, mapDispatchToProps)(DateTimePicker)
